import { useEffect } from "react";
import { gsap } from "gsap";

interface ArrowDrawAnimationProps {
  svgElement: SVGElement | null;
  animationSpeed: number;
}

const ArrowDrawAnimation: React.FC<ArrowDrawAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {

    gsap.fromTo(
      svgElement,
      { strokeDasharray: 1000, strokeDashoffset: 1000 },
      { strokeDashoffset: 0, duration: animationSpeed, ease: "power1.inOut", repeat: -1 }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default ArrowDrawAnimation;
