import { useEffect } from "react";
import { gsap } from "gsap";

interface ShimmerEffectAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const ShimmerEffectAnimation: React.FC<ShimmerEffectAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const paths = svgElement.querySelectorAll("path");

    paths.forEach((path) => {
      const length = path.getTotalLength();
      gsap.to(path, {
        strokeDasharray: length,
        strokeDashoffset: length,
        duration: animationSpeed,
        repeat: -1,
        yoyo: true,
        ease: "sine.inOut",
        stroke: "white",
        opacity: 0.5,
        stagger: 0.1,
      });
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default ShimmerEffectAnimation;
