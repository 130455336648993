import { useEffect } from "react";
import { gsap } from "gsap";

interface GlowEffectAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const GlowEffectAnimation: React.FC<GlowEffectAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("circle, rect, path");

    gsap.fromTo(
        svgElement,
      {
        opacity: 0.5,
        filter: "blur(0px)",
      },
      {
        opacity: 1,
        filter: "blur(3px)",
        duration: animationSpeed,
        ease: "power2.inOut",
        repeat: -1,
        yoyo: true,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default GlowEffectAnimation;
