import React, { useEffect } from 'react';
import { gsap } from 'gsap';

interface PieChartAnimationProps {
  slices: NodeListOf<SVGPathElement>; // Pie chart slices to animate
  duration: number; // Animation duration
}

const PieChartAnimation: React.FC<PieChartAnimationProps> = ({ slices, duration }) => {
  useEffect(() => {
    if (slices.length > 0) {
      // Animate each pie slice using GSAP
      slices.forEach((slice) => {
        gsap.fromTo(
          slice,
          { scale: 0, transformOrigin: "center center" }, // Start scale from 0
          { scale: 1, duration: duration, ease: "elastic.out(1, 0.75)" } // Animate to full size
        );

        // Add hover effect to enlarge slice on hover
        slice.addEventListener('mouseenter', () => {
          gsap.to(slice, { scale: 1.1, duration: 0.3, ease: 'power1.inOut' });
        });

        // Reset scale on mouse leave
        slice.addEventListener('mouseleave', () => {
          gsap.to(slice, { scale: 1, duration: 0.3, ease: 'power1.inOut' });
        });

        // Optionally, add click event to show details
        slice.addEventListener('click', () => {
          alert(`You clicked on slice with fill color: ${slice.getAttribute('fill')}`);
        });
      });
    }
  }, [slices, duration]);

  return null; // No visual output from this component, it's for animating the slices
};

export default PieChartAnimation;
