import React from "react";

type FileUploadProps = {
  setSvgContent: React.Dispatch<React.SetStateAction<string | null>>;
};

const FileUpload: React.FC<FileUploadProps> = ({ setSvgContent }) => {
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === "image/svg+xml") {
      const reader = new FileReader();
      reader.onload = () => {
        const svgContent = reader.result as string;
        const svg = new DOMParser().parseFromString(svgContent, "image/svg+xml").documentElement;
        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        svg.setAttribute("preserveAspectRatio", "xMidYMid meet");
        setSvgContent(new XMLSerializer().serializeToString(svg));
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="mb-3">
      <label htmlFor="uploadSVG" className="form-label">Upload SVG File</label>
      <input type="file" accept="image/svg+xml" className="form-control" id="uploadSVG" onChange={handleFileUpload} />
    </div>
  );
};

export default FileUpload;
