import { useEffect } from "react";
import { gsap } from "gsap";

interface MoveLeftAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
    moveDistance: number,
  }
  const MoveLeftAnimation: React.FC<MoveLeftAnimationProps> = ({
    svgElement,
    moveDistance = 20,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        x: `-=${moveDistance}`,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, moveDistance, animationSpeed]);
  
    return null;
  };
  
export default MoveLeftAnimation;
