import { useEffect } from "react";
import { gsap } from "gsap";

interface ElasticBounceAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const ElasticBounceAnimation: React.FC<ElasticBounceAnimationProps> = ({ svgElement, animationSpeed }) => {

  useEffect(() => {
    //const elements = svgElement.querySelectorAll("rect, circle");

    gsap.to(svgElement, {
      scaleY: 0.5,
      duration: animationSpeed * 0.5,
      repeat: -1,
      yoyo: true,
      ease: "elastic.out(1, 0.3)",
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default ElasticBounceAnimation;
