import gifshot from "gifshot";

// Ref to hold captured frames
let capturedFrames: HTMLCanvasElement[] = [];

/**
 * Function to rasterize an SVG element and convert it into a canvas.
 * @param svgElement The SVG element to be rasterized.
 * @param width The width of the canvas to render the SVG on.
 * @param height The height of the canvas to render the SVG on.
 * @returns A promise that resolves with the rasterized canvas.
 */
const rasterizeSvg = (svgElement: SVGElement, width: number, height: number): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      try {
        // Serialize SVG to a string
        const svgData = new XMLSerializer().serializeToString(svgElement);
  
        // Create a Blob for the SVG data and convert it to a URL
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);
  
        // Create an Image object to load the SVG URL
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
  
          // Use the exact original width and height for the canvas dimensions
          canvas.width = width;
          canvas.height = height;
  
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas);
          } else {
            reject("Failed to create canvas context.");
          }
  
          // Clean up the object URL
          URL.revokeObjectURL(url);
        };
  
        img.onerror = () => {
          reject("Failed to load SVG image.");
        };
  
        img.src = url;
      } catch (error) {
        reject(`Error rasterizing SVG: ${error}`);
      }
    });
  };
  

/**
 * Capture a frame of the SVG container by rasterizing the SVG and pushing it to the capturedFrames array.
 * @param svgContainerRef The reference to the SVG container.
 */
export const captureAnimationFrame = async (svgContainerRef: React.RefObject<HTMLDivElement>) => {
    const svgElement = svgContainerRef.current?.querySelector("svg");
  
    if (svgElement) {
      console.log("Rasterizing SVG before capturing frame.");
  
      // Get the actual dimensions of the SVG
      const svgWidth = svgElement.viewBox.baseVal.width || svgElement.clientWidth || 500;
      const svgHeight = svgElement.viewBox.baseVal.height || svgElement.clientHeight || 500;
  
      try {
        // Rasterize the SVG with its actual dimensions
        const canvas = await rasterizeSvg(svgElement, svgWidth, svgHeight);
  
        // Add the canvas to the captured frames
        capturedFrames.push(canvas);
  
        console.log("Captured frame after rasterizing, total frames:", capturedFrames.length);
      } catch (error) {
        console.error("Error capturing frame:", error);
      }
    } else {
      console.error("No SVG element found in container.");
    }
  };
  

/**
 * Export the captured frames as a GIF.
 * @param svgContainerRef The reference to the SVG container for getting width and height.
 */
// Export the captured frames as a GIF while maintaining aspect ratio
export const exportToGif = (svgContainerRef: React.RefObject<HTMLDivElement>, setExportProgress: (progress: number) => void) => {
  const images = capturedFrames.map((canvas) => canvas.toDataURL());

  if (svgContainerRef.current) {
    const svgElement = svgContainerRef.current.querySelector("svg");

    // Get the exact dimensions of the SVG
    const svgWidth = svgElement ? svgElement.viewBox.baseVal.width || svgElement.clientWidth : 500;
    const svgHeight = svgElement ? svgElement.viewBox.baseVal.height || svgElement.clientHeight : 500;

    gifshot.createGIF(
      {
        images,
        interval: 0.5, // 5 FPS
        gifWidth: svgWidth, // Reduced width for better performance and smaller file size  * 0.5
        gifHeight: svgHeight, // Reduced height for better performance and smaller file size  * 0.5
        numFrames: Math.min(capturedFrames.length, 70), // Limit the number of frames to improve performance
        frameDuration: 2, // Longer frame duration for smoother output
        progressCallback: (progress: number) => {
          const progressPercentage = Math.round(progress * 100);
          setExportProgress(progressPercentage); // Update progress percentage
        },
        sampleInterval: 40, // Increase sampling interval to improve performance
        numWorkers: 15, // Increase number of workers for faster processing,
        saveRenderingContexts: false,
        crossOrigin: 'Anonymous'
      },
      (obj) => {
        if (!obj.error && obj.image) {
          const link = document.createElement("a");
          link.href = obj.image;
          link.download = "animation.gif";
          link.click(); // Trigger GIF download,
        }
      }
    );
  }
};

/**
 * Start recording the SVG container's animation by capturing frames at intervals and export as a GIF.
 * @param svgContainerRef The reference to the SVG container.
 * @param duration The total duration for recording in milliseconds (default 5000ms or 5 seconds).
 */
export const startRecording = (
  svgContainerRef: React.RefObject<HTMLDivElement>,
  duration: number = 5000,
  setIsExporting?: (exporting: boolean) => void,
  setExportProgress?: (progress: number) => void
) => {
  // Clear previous frames
  capturedFrames = [];
  
  if (setIsExporting) setIsExporting(true); // Start loading

  // Capture frames at a 5 FPS rate (every 200ms)
  const captureInterval = setInterval(() => captureAnimationFrame(svgContainerRef), 100);

  // Stop recording after the specified duration and export the GIF
  setTimeout(() => {
    clearInterval(captureInterval);
    if (setExportProgress) exportToGif(svgContainerRef, setExportProgress); // Pass progress state if provided
    if (setIsExporting) setIsExporting(false); // Stop loading after exporting
  }, duration);
};



// Function to apply pixel compression on the canvas
const compressCanvas = (canvas: HTMLCanvasElement, scale: number): HTMLCanvasElement => {
  const compressedCanvas = document.createElement('canvas');
  compressedCanvas.width = canvas.width * scale;
  compressedCanvas.height = canvas.height * scale;
  const ctx = compressedCanvas.getContext('2d');

  if (ctx) {
      ctx.drawImage(canvas, 0, 0, compressedCanvas.width, compressedCanvas.height);
  }

  return compressedCanvas;
};

/**
 * Export the captured frames as a compressed GIF.
 * @param svgContainerRef The reference to the SVG container for getting width and height.
 * @param scale Scale factor for resizing (e.g., 0.5 for half the resolution).
 * @param fps Frames per second (lowering this will reduce file size).
 */
export const exportToCompressedGif = (
  svgContainerRef: React.RefObject<HTMLDivElement>,
  scale: number = 0.5,  // Scale down by half
  fps: number = 10,
  setExportProgress: (progress: number) => void      // Set the FPS to reduce the number of frames
) => {
  const compressedFrames = capturedFrames.map((canvas) => compressCanvas(canvas, scale).toDataURL());

  if (svgContainerRef.current) {
      const svgElement = svgContainerRef.current.querySelector('svg');

      const svgWidth = svgElement ? svgElement.viewBox.baseVal.width || svgElement.clientWidth : 500;
      const svgHeight = svgElement ? svgElement.viewBox.baseVal.height || svgElement.clientHeight : 500;

      gifshot.createGIF(
          {
              images: compressedFrames,
              interval: 1 / fps, // FPS setting (higher interval reduces size)
              gifWidth: svgWidth * scale,  // Compressed width
              gifHeight: svgHeight * scale,  // Compressed height
              numFrames: Math.min(capturedFrames.length, 70), // Limit frames
              frameDuration: 1 / fps, // Control frame duration
              progressCallback: (progress: number) => {
                const progressPercentage = Math.round(progress * 100);
                setExportProgress(progressPercentage); // Update progress percentage
              },
              sampleInterval: 10, // Sampling interval for pixel data (smaller = better compression)
              numWorkers: 8, // Number of workers for faster processing
          },
          (obj) => {
              if (!obj.error && obj.image) {
                  const link = document.createElement('a');
                  link.href = obj.image;
                  link.download = 'compressed_animation.gif';
                  link.click();
              }
          }
      );
  }
};

/**
 * Start recording the SVG container's animation by capturing frames at intervals and export as a GIF.
 * @param svgContainerRef The reference to the SVG container.
 * @param duration The total duration for recording in milliseconds (default 5000ms or 5 seconds).
 */
export const exportCompressedGif = (svgContainerRef: React.RefObject<HTMLDivElement>, duration: number = 5000,
  setIsExporting?: (exporting: boolean) => void,
  setExportProgress?: (progress: number) => void) => {
  // Clear previous frames
  capturedFrames = [];
  
  console.log("Recording started.");
  if (setIsExporting) setIsExporting(true); // Start loading
  // Capture frames at a 5 FPS rate (every 200ms)
  const captureInterval = setInterval(() => captureAnimationFrame(svgContainerRef), 200);

  // Stop recording after the specified duration and export the GIF
  setTimeout(() => {
    clearInterval(captureInterval);
    if (setExportProgress) exportToCompressedGif(svgContainerRef, 0.5, 10, setExportProgress);
    if (setIsExporting) setIsExporting(false);
    console.log("Recording stopped and GIF exported.");
  }, duration);
};
