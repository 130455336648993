import { useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

interface RocketFlightAnimationProps {
  svgElement: SVGElement;
  animationSpeed: number;
}

const RocketFlightAnimation: React.FC<RocketFlightAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    if (!svgElement || !svgElement.tagName) return;  // Ensure the element exists

    // Handle <use> elements differently since they don't have their own paths
    if (svgElement.tagName === 'use') {
      // Apply a simple motion animation for the <use> element (moving it up, aside, and down)
      gsap.to(svgElement, {
        duration: animationSpeed,
        y: -100,  // Moves up
        x: 50,    // Moves to the side
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut"
      });
      return;
    }

    // For other path-based elements, you can apply motionPath animation
    const flightPath = {
      curviness: 1.25,
      autoRotate: true,
      values: [
        { x: 0, y: -100 }, // Upward
        { x: 50, y: -50 }, // Move right and up
        { x: 100, y: 0 },  // Move to the side
        { x: 50, y: 50 },  // Move right and down
        { x: 0, y: 100 },  // Downward
      ],
    };

    gsap.to(svgElement, {
      duration: animationSpeed,
      motionPath: flightPath,
      repeat: -1,
      ease: "power1.inOut",
      yoyo: true,
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default RocketFlightAnimation;
