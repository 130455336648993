import React from "react";
import CirclePathAnimation from "./animations/CirclePathAnimation";
import ArrowDrawAnimation from "./animations/ArrowDrawAnimation";
import DashedArrowDrawAnimation from "./animations/DashedArrowDrawAnimation";
import DotedArrowDrawAnimation from "./animations/DotedArrowDrawAnimation";
import BasicAnimation from "./animations/BasicAnimation";
import ParallaxAnimation from "./animations/ParallaxAnimation";
import TextAnimation from "./animations/TextAnimation";
import WavingAnimation from "./animations/WavingAnimation";
import BouncingAnimation from "./animations/BouncingAnimation";
import ColorCycleAnimation from "./animations/ColorCycleAnimation";
import PoppingAnimation from "./animations/PoppingAnimation";
import ElasticBounceAnimation from "./animations/ElasticBounceAnimation";
import SwirlAnimation from "./animations/SwirlAnimation";
import FlickerAnimation from "./animations/FlickerAnimation";
import RollingAnimation from "./animations/RollingAnimation";
import BlinkingAnimation from "./animations/BlinkingAnimation";
import WindmillRotationAnimation from "./animations/WindmillRotationAnimation";
import PulseAnimation from "./animations/PulsAnimation";
import OrbitingAnimation from "./animations/OrbitingAnimation";
import ExplosionEffectAnimation from "./animations/ExplosionEffectAnimation";
import GlowEffectAnimation from "./animations/GlowEffectAnimation";
import ZoomInOutAnimation from "./animations/ZoomInOutAnimation";
import ShimmerEffectAnimation from "./animations/ShimmerEffectAnimation";
import FireflyEffectAnimation from "./animations/FireflyEffectAnimation";
import RippleEffectAnimation from "./animations/RippleEffectAnimation";
import FlipAnimation from "./animations/FlipAnimation";
import TextTypingAnimation from "./animations/TextAnimation";
import RocketFlightAnimation from "./animations/RocketFlightAnimation";
import WobbleAnimation from "./animations/WobbleAnimation";
import FloatAnimation from "./animations/FloatAnimation";
import ScaleXAnimation from "./animations/ScaleXAnimation";
import TranslateXAnimation from "./animations/TranslateXAnimation";
import TranslateYAnimation from "./animations/TranslateYAnimation";
import TranslateZAnimation from "./animations/TranslateZAnimation";
import ScaleXYAnimation from "./animations/ScaleXYAnimation";
import ScaleZAnimation from "./animations/ScaleZAnimation";
import ScaleYAnimation from "./animations/ScaleYAnimation";
import SwayAnimation from "./animations/SwayAnimation";
import SkewXAnimation from "./animations/SkewXAnimation";
import SkewXYAnimation from "./animations/SkewXYAnimation";
import SkewZAnimation from "./animations/SkewZAnimation";
import RotateXAnimation from "./animations/RotateXAnimation";
import RotateYAnimation from "./animations/RotateYAnimation";
import MoveUpAnimation from "./animations/MoveUpAnimation";
import MoveDownAnimation from "./animations/MoveDownAnimation";
import MoveLeftAnimation from "./animations/MoveLeftAnimation";
import MoveRightAnimation from "./animations/MoveRightAnimation";

interface RenderAnimationProps {
  svgElement: SVGElement | null;
  selectedElement: SVGElement | null;
  selectedAnimation: string | null;
  circleColor: string;
  circleRadius: number;
  animationSpeed: number;
  strokeColor: string;
  shape: "circle" | "square" | "triangle" | "star" | "oval" | "hexagon" | null; // Shape selection prop
  uploadedImage: string | undefined;
  imageWidth: number;
  imageHeight: number;
}

const RenderAnimation: React.FC<RenderAnimationProps> = ({
  svgElement,
  selectedElement,
  selectedAnimation,
  circleColor,
  circleRadius,
  animationSpeed,
  strokeColor,
  shape,
  uploadedImage,
  imageWidth,
  imageHeight,
}) => {
    console.log('svgElementToAnimation:', svgElement);
    console.log('selectedElementToAnimation:', selectedElement);
  switch (selectedAnimation) {
    case "circles":
      return (
        <CirclePathAnimation
          svgElement={svgElement as SVGElement}
          selectedElement={selectedElement}
          circleColor={circleColor}
          circleRadius={circleRadius}
          animationSpeed={animationSpeed}
          strokeColor={strokeColor}
          shape={shape}
          uploadedImage={uploadedImage}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
      );
    case "arrows":
      return selectedElement ? (
        <ArrowDrawAnimation svgElement={svgElement} animationSpeed={animationSpeed} />
      ) : null;
    case "dashedarrows":
      return svgElement ? (
        <DashedArrowDrawAnimation svgElement={svgElement} animationSpeed={animationSpeed} />
      ) : null;
    case "dotedarrows":
      return svgElement ? (
        <DotedArrowDrawAnimation svgElement={svgElement} animationSpeed={animationSpeed} />
      ) : null;
    case "basic":
      return (
        <BasicAnimation
          svgElement={svgElement}
          property="opacity"
          fromValue={0}
          toValue={1}
          duration={animationSpeed}
        />
      );
    case "parallax":
      return <ParallaxAnimation element={selectedElement} />;
    case "text":
        return selectedElement ? (
          <TextTypingAnimation
          textElement={selectedElement}
        />
          ) : null;
    case "wavinganimation":
      return selectedElement ? (
        <WavingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "bouncing":
      return selectedElement ? (
        <BouncingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "colors":
      return selectedElement ? (
        <ColorCycleAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "popping":
      return selectedElement ? (
        <PoppingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "elasticbounce":
      return selectedElement ? (
        <ElasticBounceAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "swirl":
      return selectedElement ? (
        <SwirlAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "flicker":
      return selectedElement ? (
        <FlickerAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "rolling":
      return selectedElement ? (
        <RollingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "blinking":
      return selectedElement ? (
        <BlinkingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "windmill":
      return selectedElement ? (
        <WindmillRotationAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "pulse":
      return selectedElement ? (
        <PulseAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "orbiting":
      return selectedElement ? (
        <OrbitingAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "explosion":
      return selectedElement ? (
        <ExplosionEffectAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "glow":
      return selectedElement ? (
        <GlowEffectAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "zoominout":
      return selectedElement ? (
        <ZoomInOutAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "shimmer":
      return selectedElement ? (
        <ShimmerEffectAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "firefly":
      return selectedElement ? (
        <FireflyEffectAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "ripple":
      return selectedElement ? (
        <RippleEffectAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "flip":
      return selectedElement ? (
        <FlipAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
    case "rocket":
      return selectedElement ? (
        <RocketFlightAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
      ) : null;
      case "wobble":
        return selectedElement ? (
          <WobbleAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
        ) : null;
      case "float":
        return selectedElement ? (
          <FloatAnimation svgElement={selectedElement} animationSpeed={animationSpeed} />
        ) : null;
      case "scalex":
        return selectedElement ? (
          <ScaleXAnimation svgElement={selectedElement} animationSpeed={animationSpeed} scaleAngle={20} />
        ) : null;
      case "scaley":
        return selectedElement ? (
          <ScaleYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} scaleAngle={-20} />
        ) : null;
      case "scalez":
        return selectedElement ? (
          <ScaleZAnimation svgElement={selectedElement} animationSpeed={animationSpeed} scaleAngle={0} />
        ) : null;
      case "scalexy":
        return selectedElement ? (
          <ScaleXYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} scaleAngle={45} />
        ) : null;
      case "translatex":
        return selectedElement ? (
          <TranslateXAnimation svgElement={selectedElement} animationSpeed={animationSpeed} translateAngle={90} />
        ) : null;
      case "translatey":
        return selectedElement ? (
          <TranslateYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} translateAngle={-90} />
        ) : null;
      case "translatez":
        return selectedElement ? (
          <TranslateZAnimation svgElement={selectedElement} animationSpeed={animationSpeed} translateAngle={180} />
        ) : null;
      case "sway":
        return selectedElement ? (
          <SwayAnimation svgElement={selectedElement} animationSpeed={animationSpeed}/>
        ) : null;
      case "skewx":
        return selectedElement ? (
          <SkewXAnimation svgElement={selectedElement} animationSpeed={animationSpeed} skewAngle={-45} />
        ) : null;
      case "skewy":
        return selectedElement ? (
          <SkewXYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} skewAngle={45} />
        ) : null;
      case "skewz":
        return selectedElement ? (
          <SkewZAnimation svgElement={selectedElement} animationSpeed={animationSpeed} skewAngle={-90} />
        ) : null;
      case "skewxy":
        return selectedElement ? (
          <SkewXYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} skewAngle={180} />
        ) : null;
      case "rotateleft":
        return selectedElement ? (
          <RotateXAnimation svgElement={selectedElement} animationSpeed={animationSpeed} rotateAngle={-90} />
        ) : null;
      case "rotateright":
        return selectedElement ? (
          <RotateYAnimation svgElement={selectedElement} animationSpeed={animationSpeed} rotateAngle={90} />
        ) : null;
      case "moveup":
        return selectedElement ? (
          <MoveUpAnimation svgElement={selectedElement} animationSpeed={animationSpeed} moveDistance={20} />
        ) : null;
      case "movedown":
        return selectedElement ? (
          <MoveDownAnimation svgElement={selectedElement} animationSpeed={animationSpeed} moveDistance={20} />
        ) : null;
      case "moveleft":
        return selectedElement ? (
          <MoveLeftAnimation svgElement={selectedElement} animationSpeed={animationSpeed} moveDistance={20} />
        ) : null;
      case "moveright":
        return selectedElement ? (
          <MoveRightAnimation svgElement={selectedElement} animationSpeed={animationSpeed} moveDistance={20} />
        ) : null;

    default:
      return null;
  }
};


export default RenderAnimation;
