import { useEffect } from "react";
import { gsap } from "gsap";

interface ScaleZAnimationProps {
    svgElement: SVGElement;
    scaleAngle: number,
    animationSpeed: number;
  }

  const ScaleZAnimation: React.FC<ScaleZAnimationProps> = ({
    svgElement,
    scaleAngle = 10,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        scaleZ: scaleAngle,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, scaleAngle, animationSpeed]);
  
    return null;
  };
   
export default ScaleZAnimation;
