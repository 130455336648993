import React, { useEffect } from "react";
import BounceAnimation from "./BouncingAnimation"; // Import the BounceAnimation component
import { gsap } from "gsap";
import ArrowDrawAnimation from "../animations/ArrowDrawAnimation";
import ColorCycleAnimationMermaid from "./ColorCycleAnimationMermaid";
import DrawEdgesAnimation from "./DrawEdgesAnimation";
import GitGraphAnimation from "./GitGraphAnimation"; // Import GitGraphAnimation
import PieChartAnimation from "./PieChartAnimation";
import MindmapAnimation from "./MindmapAnimation";
import SequenceFlowAnimation from "./SequnceFlowAnimation";
import DashedArrowDrawAnimation from "../animations/DashedArrowDrawAnimation";
import DashedArrowsMovingAnimation from "./DashedArrowsMoving";

interface RenderAnimationProps {
  svgElement: SVGElement | null;
  selectedElement: SVGElement | null;
  selectedAnimation: string | null;
  circleColor: string;
  circleRadius: number;
  animationSpeed: number;
  strokeColor: string;
  shape: string | null;
  uploadedImage: string | undefined;
  imageWidth: number;
  imageHeight: number;
}

const RenderAnimation: React.FC<RenderAnimationProps> = ({
  svgElement,
  selectedAnimation,
  animationSpeed,
}) => {
  const nodes = svgElement?.querySelectorAll(".node") as NodeListOf<SVGElement>;
  const edges = svgElement?.querySelectorAll(".flowchart-link") as NodeListOf<SVGElement>; // Updated selector for edges
  const gitGraphPaths = svgElement?.querySelectorAll(".arrow") as NodeListOf<SVGPathElement>; // Select gitGraph paths (arrows)
  const gitCommits = svgElement?.querySelectorAll(".commit") as NodeListOf<SVGCircleElement>; // Select gitGraph commits
  const pieChartSlices = svgElement?.querySelectorAll(".pieCircle") as NodeListOf<SVGPathElement>; // Select pie chart slices
  const edgess = svgElement?.querySelectorAll(".edgePath") as NodeListOf<SVGElement>; // Updated selector for edges
  useEffect(() => {
    if (svgElement && selectedAnimation) {
      // Clear previous animations
      gsap.killTweensOf(nodes);
      gsap.killTweensOf(edges);
      gsap.killTweensOf(edgess);
      gsap.killTweensOf(gitGraphPaths);
      gsap.killTweensOf(gitCommits);
      gsap.killTweensOf(pieChartSlices);
      
    }
  }, [svgElement, selectedAnimation]); // Clean up animations when the selected animation or svgElement changes

  return (
    <>
      {/* Conditionally render BounceAnimation when selectedAnimation is "bouncing" */}
      {selectedAnimation === "bouncing" && (
        <BounceAnimation nodes={nodes} duration={animationSpeed} />
      )}
      
      {/* Conditionally render DrawEdgesAnimation when selectedAnimation is "arrows" */}
      {selectedAnimation === "arrows" && (
        <DrawEdgesAnimation edges={edges} duration={animationSpeed} />
      )}

      {/* Conditionally render GitGraphAnimation when selectedAnimation is "gitGraph" */}
      {selectedAnimation === "git" && gitGraphPaths && gitCommits && (
        <GitGraphAnimation
          paths={gitGraphPaths}
          commits={gitCommits}
          duration={animationSpeed}
        />
      )}
      {selectedAnimation === "piechart" && pieChartSlices && (
        <PieChartAnimation slices={pieChartSlices} duration={animationSpeed} />
      )}
       {selectedAnimation === "mindmap" && (
        <MindmapAnimation nodes={nodes} edges={edgess} duration={animationSpeed} />
      )}
      {selectedAnimation === "sequnceflow" && (
        <SequenceFlowAnimation svgElement={svgElement} duration={animationSpeed} />
      )}
      {selectedAnimation === "dashedarrows" && (
        <DashedArrowsMovingAnimation svgElement={svgElement} duration={animationSpeed} />
      )}
      {/* You can add other animation cases here if needed */}
    </>
  );
};

export default RenderAnimation;
