import { useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

interface SwirlAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const SwirlAnimation: React.FC<SwirlAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    //const circles = svgElement.querySelectorAll("circle");

    gsap.to(svgElement, {
      motionPath: {
        path: "M150,150 Q200,50 250,150 T350,150",
        align: "self",
        alignOrigin: [0.5, 0.5],
      },
      duration: animationSpeed,
      repeat: -1,
      ease: "power2.inOut",
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default SwirlAnimation;
