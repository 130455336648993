import { useEffect } from "react";
import { gsap } from "gsap";

interface ParallaxAnimationProps {
  element: SVGElement | null;
}

const ParallaxAnimation: React.FC<ParallaxAnimationProps> = ({ element }) => {
  useEffect(() => {
    if (!element) return;

    gsap.to(element, {
      yPercent: -50,
      ease: "none",
      scrollTrigger: {
        trigger: element,
        start: "top bottom",
        scrub: true,
      },
    });
  }, [element]);

  return null;
};

export default ParallaxAnimation;
