import { useEffect } from "react";
import { gsap } from "gsap";

interface RippleEffectAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const RippleEffectAnimation: React.FC<RippleEffectAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const circles = svgElement.querySelectorAll("circle");

    gsap.fromTo(
        svgElement,
      {
        scale: 0,
        opacity: 1,
      },
      {
        scale: 2,
        opacity: 0,
        duration: animationSpeed,
        ease: "power2.out",
        repeat: -1,
        stagger: 0.2,
        yoyo: true,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default RippleEffectAnimation;
