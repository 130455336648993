import React, { useEffect } from "react";
import { gsap } from "gsap";

interface MermaidFlowChartAnimationProps {
  svgElement: SVGElement | null; // Pass the SVG element for the flowchart
  duration: number; // Animation duration
}

const DashedArrowsMovingAnimation: React.FC<MermaidFlowChartAnimationProps> = ({
  svgElement,
  duration,
}) => {
  useEffect(() => {
    if (svgElement) {
      const dashedLines = svgElement.querySelectorAll(".messageLine1") as NodeListOf<SVGLineElement>;

      // Animate dashed lines
      gsap.fromTo(
        dashedLines,
        { strokeDashoffset: 10 }, // Start with an offset for animation effect
        {
          strokeDashoffset: 0, // End of animation
          duration: duration,
          repeat: -1, // Infinite loop
          ease: "linear",
          onStart: () => {
            dashedLines.forEach((line) => {
              gsap.set(line, { stroke: "#333", strokeWidth: 2, strokeDasharray: "3, 3" });
            });
          }
        }
      );
    }
  }, [svgElement, duration]);

  return null; // No visual output, just the animation
};

export default DashedArrowsMovingAnimation;
