import { useEffect } from "react";
import { gsap } from "gsap";

interface SkewXAnimationProps {
    svgElement: SVGElement;
    skewAngle: number,
    animationSpeed: number;
  }

  const SkewXAnimation: React.FC<SkewXAnimationProps> = ({
    svgElement,
    skewAngle = 20,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        skewX: skewAngle,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, skewAngle, animationSpeed]);
  
    return null;
  };
   
export default SkewXAnimation;
