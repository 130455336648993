import { useEffect } from "react";
import { gsap } from "gsap";

interface DotedArrowDrawAnimationProps {
  svgElement: SVGElement;
  animationSpeed: number;
}

const DotedArrowDrawAnimation: React.FC<DotedArrowDrawAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    // Select all paths that have dotted stroke (you'll define dotted strokes in your SVG or dynamically)
    const dottedArrows = svgElement.querySelectorAll('path[stroke-dasharray]');

    // Apply GSAP animation to dotted arrows
    dottedArrows.forEach((pathElement) => {
      gsap.to(pathElement, {
        strokeDashoffset: -1000, // Makes the dots move backward
        duration: animationSpeed, // Control the speed of the dot movement
        ease: "linear", // Continuous linear movement
        repeat: -1, // Infinite loop
      });
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default DotedArrowDrawAnimation;
