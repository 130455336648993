export const iconMapping: Record<string, string> = {
  "Azure Storage": "/icons/azure/storage/10086-icon-service-Storage-Accounts.svg",
  "Azure Cosmos DB": "/icons/azure/databases/10121-icon-service-Azure-Cosmos-DB.svg",
  "Azure SQL Database": "/icons/azure/databases/10130-icon-service-SQL-Database.svg",
  "Azure Database for MySQL": "/icons/azure/databases/10120-icon-service-Azure-Database-for-MySQL.svg",
  "Azure Database for PostgreSQL": "/icons/azure/databases/10127-icon-service-Azure-Database-for-PostgreSQL.svg",
  "Azure Database for MariaDB": "/icons/azure/databases/10119-icon-service-Azure-Database-for-MariaDB.svg",
  "SQL Managed Instance": "/icons/azure/databases/10131-icon-service-SQL-Managed-Instance.svg",
  "Azure Cache for Redis": "/icons/azure/databases/10116-icon-service-Azure-Cache-for-Redis.svg",
  "SQL Data Warehouse": "/icons/azure/databases/10129-icon-service-SQL-Data-Warehouse.svg",
  "Azure Synapse Analytics": "/icons/azure/databases/10122-icon-service-Synapse-Analytics.svg",
  "Database Migration Service": "/icons/azure/databases/10118-icon-service-Database-Migration-Service.svg",
  "Blob Storage": "/icons/azure/storage/10088-icon-service-Blob-Storage.svg",
  "File Storage": "/icons/azure/storage/10089-icon-service-File-Storage.svg",
  "Queue Storage": "/icons/azure/storage/10091-icon-service-Queue-Storage.svg",
  "Table Storage": "/icons/azure/storage/10092-icon-service-Table-Storage.svg",
  "Data Lake Storage": "/icons/azure/storage/10094-icon-service-Data-Lake-Storage.svg",
  "NetApp Files": "/icons/azure/storage/10096-icon-service-NetApp-Files.svg",
  "Azure Backup": "/icons/azure/storage/10090-icon-service-Azure-Backup.svg",
  "StorSimple": "/icons/azure/storage/10093-icon-service-StorSimple.svg",
  "Virtual Network": "/icons/azure/networking/10043-icon-service-Virtual-Network.svg",
  "Load Balancer": "/icons/azure/networking/10046-icon-service-Load-Balancer.svg",
  "Application Gateway": "/icons/azure/networking/10044-icon-service-Application-Gateway.svg",
  "VPN Gateway": "/icons/azure/networking/10047-icon-service-VPN-Gateway.svg",
  "ExpressRoute": "/icons/azure/networking/10042-icon-service-ExpressRoute.svg",
  "Traffic Manager": "/icons/azure/networking/10045-icon-service-Traffic-Manager.svg",
  "Azure Firewall": "/icons/azure/networking/10039-icon-service-Azure-Firewall.svg",
  "Content Delivery Network (CDN)": "/icons/azure/networking/10041-icon-service-Content-Delivery-Network.svg",
  "Front Door": "/icons/azure/networking/10038-icon-service-Front-Door.svg",
  "DDoS Protection": "/icons/azure/networking/10040-icon-service-DDoS-Protection.svg",
  "Route Table": "/icons/azure/networking/10048-icon-service-Route-Table.svg",
  "HDInsight": "/icons/azure/analytics/10111-icon-service-HDInsight.svg",
  "Databricks": "/icons/azure/analytics/10109-icon-service-Databricks.svg",
  "Data Factory": "/icons/azure/analytics/10108-icon-service-Data-Factory.svg",
  "Stream Analytics": "/icons/azure/analytics/10124-icon-service-Stream-Analytics.svg",
  "Log Analytics": "/icons/azure/analytics/10113-icon-service-Log-Analytics.svg",
  "Event Hubs": "/icons/azure/analytics/10110-icon-service-Event-Hubs.svg",
  "Machine Learning": "/icons/azure/analytics/10114-icon-service-Machine-Learning.svg",
  "Azure Analysis Services": "/icons/azure/analytics/10105-icon-service-Analysis-Services.svg",
  "Data Explorer": "/icons/azure/analytics/10107-icon-service-Data-Explorer.svg",
  "App Services": "/icons/azure/app services/10035-icon-service-App-Services.svg",
  "App Service": "/icons/azure/app services/10035-icon-service-App-Services.svg",
  "Azure App Services": "/icons/azure/app services/10035-icon-service-App-Services.svg",
  "Azure App Service": "/icons/azure/app services/10035-icon-service-App-Services.svg",
  "Functions": "/icons/azure/app services/10102-icon-service-Functions.svg",
  "Logic Apps": "/icons/azure/app services/10104-icon-service-Logic-Apps.svg",
  "API Management": "/icons/azure/app services/10103-icon-service-API-Management.svg",
  "Azure Kubernetes Service": "/icons/azure/compute/10023-icon-service-Kubernetes-Services.svg",
  "Container Instances": "/icons/azure/compute/10098-icon-service-Container-Instances.svg",
  "Container Registry": "/icons/azure/compute/10100-icon-service-Container-Registry.svg",
  "Virtual Machines": "/icons/azure/compute/10021-icon-service-Virtual-Machine.svg",
  "Static Web Apps": "/icons/azure/web/10126-icon-service-Static-Web-Apps.svg",
  "Web PubSub": "/icons/azure/web/10128-icon-service-Web-PubSub.svg",
  "SignalR Service": "/icons/azure/web/10129-icon-service-SignalR-Service.svg",
  "Azure Functions": "/icons/azure/web/10056-icon-service-Functions.svg",
  "Azure Web App": "/icons/azure/web/10054-icon-service-Web-App.svg",
  "Visual Studio Code": "/icons/azure/developer_tools/10080-icon-service-Visual-Studio-Code.svg",
  "Azure DevOps": "/icons/azure/developer_tools/10081-icon-service-Azure-DevOps.svg",
  "GitHub": "/icons/azure/developer_tools/10082-icon-service-GitHub.svg",
  "App Configuration": "/icons/azure/developer_tools/10083-icon-service-App-Configuration.svg",
  "Azure Pipelines": "/icons/azure/developer_tools/10084-icon-service-Azure-Pipelines.svg",
  "Azure Repos": "/icons/azure/developer_tools/10085-icon-service-Azure-Repos.svg",
  "Azure Policy": "/icons/azure/management_governance/10103-icon-service-Azure-Policy.svg",
  "Cost Management + Billing": "/icons/azure/management_governance/10104-icon-service-Cost-Management-Billing.svg",
  "Resource Graph": "/icons/azure/management_governance/10102-icon-service-Resource-Graph.svg",
  "Azure Advisor": "/icons/azure/management_governance/10101-icon-service-Azure-Advisor.svg",
  "Automation": "/icons/azure/management_governance/10098-icon-service-Automation.svg",
  "Blueprints": "/icons/azure/management_governance/10099-icon-service-Blueprints.svg",
  "Service Health": "/icons/azure/management_governance/10100-icon-service-Service-Health.svg",
  "AWS S3": "/icons/aws/s3.svg",
  "AWS Lambda": "/icons/aws/lambda.svg",
  // Add more mappings as needed
};
