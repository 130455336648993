import { useEffect } from "react";
import { gsap } from "gsap";

interface DashedArrowDrawAnimationProps {
  svgElement: SVGElement | null;
  animationSpeed: number;
}

const DashedArrowDrawAnimation: React.FC<DashedArrowDrawAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    // Select all arrow paths that have a dashed stroke
    const dashedArrows = svgElement?.querySelectorAll('path[stroke-dasharray]');

    // Apply GSAP animation to dashed arrows
    dashedArrows?.forEach((pathElement) => {
      gsap.to(pathElement, {
        strokeDashoffset: -1000, // Makes the dashes move backward
        duration: animationSpeed, // Control the speed
        ease: "linear", // Linear movement for continuous effect
        repeat: -1, // Infinite loop
      });
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default DashedArrowDrawAnimation;
