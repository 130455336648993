import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

interface TextTypingAnimationProps {
  textElement: SVGElement;  // Expecting a specific text element instead of the whole SVG
}

const TextTypingAnimation: React.FC<TextTypingAnimationProps> = ({ textElement }) => {
  useEffect(() => {
    if (!textElement) return;

    // Register the TextPlugin
    gsap.registerPlugin(TextPlugin);

    const phrase = textElement.textContent || ''; // Get the text content of the selected element
    console.log(phrase);
    // Typing effect using GSAP's TextPlugin
    const typingTimeline = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });

    typingTimeline.to(textElement, {
      duration: 2,
      text: phrase,
      ease: 'power2.inOut',
    });

    typingTimeline.play();
  }, [textElement]);

  return null;
};

export default TextTypingAnimation;
