import { useEffect } from "react";
import { gsap } from "gsap";

interface BasicAnimationProps {
  svgElement: SVGElement | null;
  property: string;
  fromValue: number;
  toValue: number;
  duration: number;
}

const BasicAnimation: React.FC<BasicAnimationProps> = ({ svgElement, property, fromValue, toValue, duration }) => {
  useEffect(() => {
    if (!svgElement) return;

    const elements = svgElement.querySelectorAll('path[stroke="#1e1e1e"]');

    gsap.fromTo(
      elements,
      { [property]: fromValue },
      { [property]: toValue, duration, ease: "power1.inOut", repeat: -1 }
    );
  }, [svgElement, property, fromValue, toValue, duration]);

  return null;
};

export default BasicAnimation;
