import { useEffect } from "react";
import { gsap } from "gsap";

interface ScaleXYAnimationProps {
    svgElement: SVGElement;
    scaleAngle: number,
    animationSpeed: number;
  }

  const ScaleXYAnimation: React.FC<ScaleXYAnimationProps> = ({
    svgElement,
    scaleAngle = 10,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        scaleXY: scaleAngle,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, scaleAngle, animationSpeed]);
  
    return null;
  };
   
export default ScaleXYAnimation;
