import { useEffect } from "react";
import { gsap } from "gsap";

interface ZoomInOutAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const ZoomInOutAnimation: React.FC<ZoomInOutAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("circle, rect");

    gsap.to(svgElement, {
      scale: 1.2,
      duration: animationSpeed,
      yoyo: true,
      repeat: -1,
      ease: "power2.inOut",
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default ZoomInOutAnimation;
