import { useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

interface OrbitingAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const OrbitingAnimation: React.FC<OrbitingAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("circle");

    elements.forEach((element, index) => {
      gsap.to(element, {
        motionPath: {
          path: `M100,100 A${50 + index * 20},${50 + index * 20} 0 1,1 100,100`,
          align: "self",
        },
        duration: animationSpeed + index,
        repeat: -1,
        ease: "none",
      });
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default OrbitingAnimation;
