import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "bootstrap/dist/css/bootstrap.min.css";

// Types
interface SVGTemplate {
  name: string;
  category: string;
  path: string;
  metadata: { description: string };
}

type SVGSelectorProps = {
  templates: SVGTemplate[];
  setSvgContent: React.Dispatch<React.SetStateAction<string | null>>;
};

// Component for SVG Template Selector with Modal
const SVGSelector: React.FC<SVGSelectorProps> = ({ templates, setSvgContent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Controls modal open/close
  const [previewSvg, setPreviewSvg] = useState<string | null>(null); // For previewing
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const filteredTemplates = templates.filter(
    (template) => selectedCategory === "All" || template.category === selectedCategory
  );

  // Handle choosing an SVG template (Use button)
  const handleUseSVG = async (path: string) => {
    const response = await fetch(path);
    const svgContent = await response.text();
    setSvgContent(svgContent);
    setIsModalOpen(false); // Close modal on use
  };

  // Handle SVG preview
  const handlePreviewSVG = (path: string) => {
    setPreviewSvg(path);
  };

  return (
    <div>
      {/* Button to open the modal */}
      <button className="btn btn-primary mb-3" onClick={() => setIsModalOpen(true)}>
        Choose Template
      </button>

      {/* Modal for choosing the template */}
      {isModalOpen && (
        <div className="modal show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select an SVG Template</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsModalOpen(false)}
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                {/* Category Filter */}
                <div className="mb-4 d-flex flex-wrap gap-2">
                  {[
                    { label: "All", icon: "fluent-color:cloud-16" },
                    { label: "AWS", icon: "devicon:amazonwebservices-wordmark" },
                    { label: "Azure", icon: "devicon:azure" },
                    { label: "GCP", icon: "logos:google-cloud" },
                    { label: "DevOps", icon: "devicon:azuredevops" },
                  ].map((category) => (
                    <button
                      key={category.label}
                      className={`btn btn-outline-secondary ${
                        selectedCategory === category.label ? "active" : ""
                      }`}
                      onClick={() => setSelectedCategory(category.label)}
                    >
                      <Icon icon={category.icon} className="mr-2" /> {category.label}
                    </button>
                  ))}
                </div>

                {/* Template Grid */}
                <div className="row">
                  {filteredTemplates.map((template) => (
                    <div key={template.name} className="col-md-4 mb-4">
                      <div className="card h-100 shadow-sm">
                        <img
                          src={template.path}
                          alt={template.name}
                          className="card-img-top p-2"
                          style={{ maxHeight: "200px", objectFit: "contain" }}
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">{template.name}</h5>
                          <p className="card-text text-muted">{template.metadata.description}</p>
                        </div>
                        <div className="card-footer d-flex justify-content-between p-2">
                          <button
                            className="btn btn-outline-info"
                            onClick={() => handlePreviewSVG(template.path)}
                          >
                            <Icon icon="solar:eye-broken" /> Preview
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={() => handleUseSVG(template.path)}
                          >
                            <Icon icon="solar:eye-broken" /> Use
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Preview Modal */}
      {previewSvg && (
        <div className="modal show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">SVG Preview</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPreviewSvg(null)}
                >
                </button>
              </div>
              <div className="modal-body text-center">
                <img src={previewSvg} alt="SVG Preview" className="img-fluid" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setPreviewSvg(null)}
                >
                  Close Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SVGSelector;
