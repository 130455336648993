import { useEffect } from "react";
import { gsap } from "gsap";

interface FlickerAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const FlickerAnimation: React.FC<FlickerAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    //const elements = svgElement.querySelectorAll("path");

    gsap.to(svgElement, {
      opacity: gsap.utils.random(0.2, 1),
      duration: animationSpeed * 0.1,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut",
      repeatRefresh: true, // Refresh values on repeat for random flicker effect
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default FlickerAnimation;
