import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Icon } from '@iconify/react'; // Import Iconify for icons

interface RemoveAnimationProps {
  svgElement: SVGElement;
}

const RemoveAnimation: React.FC<RemoveAnimationProps> = ({ svgElement }) => {
  const [selectedElement, setSelectedElement] = useState<SVGElement | null>(null);

  useEffect(() => {
    // Add click event listeners to all SVG elements to select them
    const handleElementClick = (event: MouseEvent) => {
      const target = event.target as SVGElement;
      setSelectedElement(target); // Set the selected element
    };

    // Adding event listener for each SVG element
    svgElement.querySelectorAll<SVGElement>("*").forEach((element) => {
      element.addEventListener("click", handleElementClick as EventListener);
    });

    // Cleanup event listeners on unmount
    return () => {
      svgElement.querySelectorAll<SVGElement>("*").forEach((element) => {
        element.removeEventListener("click", handleElementClick as EventListener);
      });
    };
  }, [svgElement]);

  // Function to remove all animations from the selected element
  const removeAnimations = () => {
    if (selectedElement) {
      // Kill all GSAP animations on the selected element
      gsap.killTweensOf(selectedElement);

      // Reset any inline styles applied by animations (e.g., transforms, opacity)
      selectedElement.removeAttribute("style");
    }
  };

  return (
    <div>
      {/* Display which element is selected */}
      <p>Selected Element: {selectedElement ? selectedElement.nodeName : "None"}</p>

      {/* Button to remove animations with icon and tooltip */}
      <div
        className="col-3 d-flex flex-column align-items-center mb-2"
        role="button"
        aria-label="Remove Animations"
        onClick={removeAnimations}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Remove Animations"
        style={{
          cursor: !selectedElement ? 'not-allowed' : 'pointer',
          width: "60px", // icon width
          height: "60px", // icon height
          opacity: !selectedElement ? 0.5 : 1, // Set opacity based on disabled state
          pointerEvents: !selectedElement ? 'none' : 'auto', // Disable pointer events when disabled
        }}
      >
        <Icon icon="solar:notification-remove-broken" width="36" height="36" />
        <span style={{ fontSize: "12px", marginTop: "5px" }}></span>
      </div>
    </div>
  );
};

export default RemoveAnimation;
