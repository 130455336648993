import { useEffect } from "react";
import { gsap } from "gsap";

interface RollingAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const RollingAnimation: React.FC<RollingAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    //const elements = svgElement.querySelectorAll("circle, rect");

    gsap.to(svgElement, {
      x: "+=200",
      rotation: 360,
      duration: animationSpeed,
      repeat: -1,
      ease: "power2.inOut",
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default RollingAnimation;
