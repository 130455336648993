import React, { useEffect, useState } from 'react';
import mermaid from 'mermaid';
import RenderAnimation from './RenderMermaidAnimation';
import { iconMapping } from './iconMapping';

interface MermaidRendererProps {
  mermaidSyntax: string;
  svgContainerRef: React.RefObject<HTMLDivElement>;
  onRender?: () => void;
  onElementClick?: (element: SVGElement) => void;
  selectedAnimation: string | null;
  animationSpeed: number;
}

const replaceNodeWithIcon = (syntax: string): string => {
  let modifiedSyntax = syntax;

  for (const [service, iconPath] of Object.entries(iconMapping)) {
    const regex = new RegExp(`\\b${service}\\b`, "g");
    modifiedSyntax = modifiedSyntax.replace(
      regex,
      `<img src="${iconPath}" alt="${service}" width="50" height="50" style="vertical-align: middle;"> ${service}`
    );
  }

  return modifiedSyntax;
};

const MermaidRenderer: React.FC<MermaidRendererProps> = ({
  mermaidSyntax,
  svgContainerRef,
  onRender,
  onElementClick,
  selectedAnimation,
  animationSpeed,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [svgElement, setSvgElement] = useState<SVGElement | null>(null);

  useEffect(() => {
    if (svgContainerRef.current) {
      try {
        svgContainerRef.current.innerHTML = '';

        const modifiedSyntax = replaceNodeWithIcon(mermaidSyntax);

        mermaid.parse(modifiedSyntax);

        const svgId = 'generated-mermaid';
        mermaid
          .render(svgId, modifiedSyntax, svgContainerRef.current)
          .then(({ svg }) => {
            setError(null);
            if (svgContainerRef.current) {
              svgContainerRef.current.innerHTML = svg;

              const svgElement = svgContainerRef.current.querySelector('svg');
              if (svgElement) {
                setSvgElement(svgElement);

                svgElement.setAttribute('width', '100%');
                svgElement.setAttribute('preserveAspectRatio', 'xMinYMin meet');

                if (!svgElement.hasAttribute('viewBox')) {
                  const width = svgElement.getBBox().width;
                  const height = svgElement.getBBox().height;
                  svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
                }

                svgElement.style.backgroundColor = "#f0f0f0"; // Set desired background color

                const nodesAndEdges = svgElement.querySelectorAll('.node, .edgePath');
                nodesAndEdges.forEach((element) => {
                  element.addEventListener('click', () => onElementClick?.(element as SVGElement));
                });

                if (onRender) {
                  onRender();
                }
              }
            }
          })
          .catch((err) => {
            setError('Error rendering Mermaid diagram');
            console.error(err);
          });
      } catch (e) {
        setError('Invalid Mermaid syntax');
        console.error(e);
      }
    }
  }, [mermaidSyntax, svgContainerRef, onRender, onElementClick]);

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      {svgElement && (
        <RenderAnimation
          svgElement={svgElement}
          selectedElement={null}
          selectedAnimation={selectedAnimation}
          animationSpeed={animationSpeed}
          circleColor="blue"
          circleRadius={5}
          strokeColor="#000"
          shape="circle"
          uploadedImage={undefined}
          imageWidth={50}
          imageHeight={50}
        />
      )}
    </div>
  );
};

export default MermaidRenderer;
