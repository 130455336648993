import React, { useEffect } from "react";
import { gsap } from "gsap";

interface SequenceFlowAnimationProps {
  svgElement: SVGElement | null; // Pass the SVG element for the sequence diagram
  duration: number; // Animation duration
}

const SequenceFlowAnimation: React.FC<SequenceFlowAnimationProps> = ({
  svgElement,
  duration,
}) => {
  useEffect(() => {
    if (svgElement) {
      const lines = svgElement.querySelectorAll(".messageLine0, .messageLine1") as NodeListOf<SVGLineElement>;
      const texts = svgElement.querySelectorAll(".messageText") as NodeListOf<SVGTextElement>;

      // Animate the lines being drawn
      gsap.fromTo(
        lines,
        { stroke: "none", strokeWidth: 2 },
        {
          stroke: "#333",
          duration: duration,
          stagger: 1,
          onStart: function () {
            gsap.to(texts, {
              fill: "#333",
              duration: duration / 2,
              stagger: 1,
              ease: "power2.inOut",
            });
          },
          ease: "power2.inOut",
        }
      );
    }
  }, [svgElement, duration]);

  return null; // No visual output, just the animation
};

export default SequenceFlowAnimation;
