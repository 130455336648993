import React, { useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

interface GitGraphAnimationProps {
  paths: NodeListOf<SVGPathElement>; // Paths (arrows) to animate along
  commits: NodeListOf<SVGCircleElement>; // Commits where the animation will pause
  duration: number; // Duration of the animation along each path
}

const GitGraphAnimation: React.FC<GitGraphAnimationProps> = ({ paths, commits, duration }) => {
  useEffect(() => {
    if (paths.length === 0 || commits.length === 0) return;

    const movingCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    movingCircle.setAttribute("r", "5");
    movingCircle.setAttribute("fill", "blue");
    commits[0].parentElement?.appendChild(movingCircle); // Add the moving circle to the first commit's parent

    const tl = gsap.timeline({
      defaults: { duration: duration, ease: "power2.inOut" }
    });

    // Animate the circle along the paths and pause at each commit
    paths.forEach((path, index) => {
      tl.to(movingCircle, {
        motionPath: {
          path: path,
          align: path,
          alignOrigin: [0.5, 0.5],
          autoRotate: true,
        }
      });

      // Add a pause at each commit and highlight the commit circle
      if (index < commits.length) {
        tl.to(movingCircle, {
          duration: 0.5,
          onComplete: () => {
            // Optionally, highlight the commit circle
            gsap.to(commits[index], { fill: "#FF0000", duration: 0.5 });
          },
        }, "+=0.5"); // Pause for 0.5s at each commit
      }
    });

    return () => {
      tl.kill(); // Cleanup animation when component is unmounted
      movingCircle.remove(); // Remove the circle when the component unmounts
    };
  }, [paths, commits, duration]);

  return null; // This component handles animation, no UI to render
};

export default GitGraphAnimation;
