import React from 'react';

interface GifDurationControlProps {
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
}

const GifDurationControl: React.FC<GifDurationControlProps> = ({ duration, setDuration }) => {
  return (
    <div className="mt-4">
      <label htmlFor="animationSpeed" className="form-label">
        GIF Duration (seconds): 
      </label>
      <input
        type="range"
        min="5"
        max="20"
        value={duration}
        className="slider-custom"
        onChange={(e) => setDuration(Number(e.target.value))}
      />
      <span className="speed-label">{duration}s</span>
    </div>
  );
};

export default GifDurationControl;

