// ErrorPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  return (
    <div className="error-page-container">
      <img src='./errorimg.svg' alt="Error" style={{ width: "500px", height: "auto" }} />
      <p>Oops! The page you're looking for could not be found.</p>
      <Link to="/" className="btn btn-primary">
        Go back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
