import React, { useEffect } from "react";
import { gsap } from "gsap";

interface DrawEdgesAnimationProps {
  edges: NodeListOf<SVGElement>; // Pass the edges to animate
  duration: number; // Animation duration
}

const DrawEdgesAnimation: React.FC<DrawEdgesAnimationProps> = ({ edges, duration }) => {
  useEffect(() => {
    if (edges.length > 0) {
      // Animate the drawing of edges (arrows)
      gsap.from(edges, {
        strokeDasharray: "5, 5",
        stroke: "transparent",
        duration: duration,
        stagger: 0.3,
        onComplete: () => {
          gsap.to(edges, { stroke: "#000", duration: 0.5 }); // Smooth final appearance
        },
      });
    }
  }, [edges, duration]); // Re-run the effect when edges or duration changes

  return null; // No UI is rendered by this component
};

export default DrawEdgesAnimation;
