import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import FileUpload from "./FileUpload";
import { Icon } from "@iconify/react";
import SVGSelector from "./SVGSelector";

interface SVGOptionsModalProps {
  templates: any[];
  setSvgContent: React.Dispatch<React.SetStateAction<string | null>>;
  mermaidSyntax: string;
  setMermaidSyntax: React.Dispatch<React.SetStateAction<string>>;
  setShowMermaid: React.Dispatch<React.SetStateAction<boolean>>;
}

const SVGOptionsModal: React.FC<SVGOptionsModalProps> = ({
  templates,
  setSvgContent,
  mermaidSyntax,
  setMermaidSyntax,
  setShowMermaid,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add SVG or Diagram
      </Button>

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select an Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-around">
            {/* Option 1: Add from Template */}
            <Col xs={4} className="text-center">
              <div className="option-card">
                <img
                  src="./process.png"
                  width={80}
                  alt="Template placeholder"
                  className="mb-3 img-fluid"
                />
                <h5>Add from Template</h5>
                <p>Choose from a variety of SVG templates</p>
                <SVGSelector templates={templates} setSvgContent={setSvgContent} />
              </div>
            </Col>

            {/* Option 2: Upload SVG */}
            <Col xs={4} className="text-center">
              <div className="option-card">
                <img
                  src="./image-upload.png"
                  width={80}
                  alt="Upload placeholder"
                  className="mb-3 img-fluid"
                />
                <h5>Upload SVG</h5>
                <p>Upload your own SVG file</p>
                <FileUpload setSvgContent={setSvgContent} />
              </div>
            </Col>

            {/* Option 3: Add Mermaid Diagram */}
            <Col xs={4} className="text-center">
              <div className="option-card">
                <img
                  src="./diagram.png"
                  width={80}
                  alt="Mermaid placeholder"
                  className="mb-3 img-fluid"
                />
                <h5>Mermaid Diagram</h5>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder="Paste Mermaid syntax here to generate a diagram"
                  value={mermaidSyntax}
                  onChange={(e) => setMermaidSyntax(e.target.value)}
                />
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => {
                    setShowMermaid(true);
                    handleClose();
                  }}
                >
                  Render Mermaid
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SVGOptionsModal;
