import React from "react";
import { FaCompress, FaImage } from "react-icons/fa";

interface ExportSvgProps {
  svgElement: SVGElement | null | undefined;
}

const ExportSvg: React.FC<ExportSvgProps> = ({ svgElement }) => {
  const exportAsSvg = () => {
    if (!svgElement) return;

    const svgData = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "diagram.svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
              className="styled-button"
              onClick={exportAsSvg}
            >
              <FaImage style={{ marginRight: "8px" }} />
              Export SVG
            </button>
  );
};

export default ExportSvg;
