import React, { useEffect } from "react";
import { gsap } from "gsap";

interface BounceAnimationProps {
  nodes: NodeListOf<SVGElement>; // Pass the nodes to animate
  duration: number; // Animation duration
}

const BounceAnimation: React.FC<BounceAnimationProps> = ({ nodes, duration }) => {
  useEffect(() => {
    if (nodes.length > 0) {
      // Apply the bounce animation
      gsap.from(nodes, {
        y: -50,
        duration: duration,
        ease: "bounce.out",
        stagger: 0.2,
      });
    }
  }, [nodes, duration]); // Re-run the effect when nodes or duration changes

  return null; // No UI is rendered by this component
};

export default BounceAnimation;
