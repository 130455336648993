import React from "react";

interface CircleRadiusControlProps {
  circleRadius: number;
  setCircleRadius: React.Dispatch<React.SetStateAction<number>>;
}

const CircleRadiusControl: React.FC<CircleRadiusControlProps> = ({ circleRadius, setCircleRadius }) => {
  return (
    <div className="mb-3">
      <label htmlFor="circleRadius" className="form-label">Shape Radius</label>
      <input
        type="number"
        className="form-control"
        value={circleRadius}
        onChange={(e) => setCircleRadius(Number(e.target.value))}
      />
    </div>
  );
};

export default CircleRadiusControl;
