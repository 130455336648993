import { useEffect } from "react";
import { gsap } from "gsap";

interface BouncingAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const BouncingAnimation: React.FC<BouncingAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    gsap.fromTo(
        svgElement,
      { y: -10 },
      {
        y: 0,
        duration: animationSpeed,
        ease: "bounce.out",
        repeat: -1,
        yoyo: true,
        stagger: 0.2,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default BouncingAnimation;
