import { useEffect } from "react";
import { gsap } from "gsap";

interface FlipAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const FlipAnimation: React.FC<FlipAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("rect, polygon");

    gsap.fromTo(
        svgElement,
      { rotationY: 0 },
      {
        rotationY: 180,
        duration: animationSpeed,
        ease: "power2.inOut",
        repeat: -1,
        yoyo: true,
        transformOrigin: "50% 50%",
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default FlipAnimation;
