import { useEffect } from "react";
import { gsap } from "gsap";

interface WavingAnimationnProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const WavingAnimation: React.FC<WavingAnimationnProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {


    gsap.fromTo(
        svgElement,
      {
        rotation: 0,
      },
      {
        rotation: 5,
        yoyo: true,
        duration: animationSpeed,
        ease: "sine.inOut",
        repeat: -1,
        stagger: 0.1,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default WavingAnimation;
