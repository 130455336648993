import { useEffect } from "react";
import { gsap } from "gsap";

interface SwayAnimationProps {
  svgElement: SVGElement;
  swayDistance?: number;  // Optional distance for the sway effect
  animationSpeed?: number; // Optional speed for the sway effect
}

const SwayAnimation: React.FC<SwayAnimationProps> = ({
  svgElement,
  swayDistance = 15,  // Default sway distance of 15px
  animationSpeed = 1.5,  // Default animation duration of 1.5 seconds
}) => {
  useEffect(() => {
    gsap.to(svgElement, {
      x: `+=${swayDistance}`,
      duration: animationSpeed,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,  // Make the sway go back and forth
    });
  }, [svgElement, swayDistance, animationSpeed]);

  return null;
};

export default SwayAnimation;
