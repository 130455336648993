import { useEffect } from "react";
import { gsap } from "gsap";

interface ColorCycleAnimationProps {
    svgElement: SVGElement | NodeListOf<SVGElement>;
    animationSpeed: number;
  }
const ColorCycleAnimation: React.FC<ColorCycleAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    

    gsap.to(svgElement, {
      fill: gsap.utils.wrap([
        "#33FF57", // Bright green
        "#3357FF", // Bright blue
        "#F333FF", // Bright pink
        "#33FFF5", // Bright cyan
        "#FFEB33", // Bright yellow
        "#FF33D7", // Bright magenta
        "#33FFB2"  // Bright teal
      ]),
      stroke: gsap.utils.wrap([
        "#33FF57", // Bright green
        "#3357FF", // Bright blue
        "#F333FF", // Bright pink
        "#33FFF5", // Bright cyan
        "#FFEB33", // Bright yellow
        "#FF33D7", // Bright magenta
        "#33FFB2"  // Bright teal
      ]),
      
      duration: animationSpeed,
      repeat: -1,
      yoyo: true,
      ease: "linear",
      stagger: 0.2,
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default ColorCycleAnimation;
