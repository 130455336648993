import { useEffect } from "react";
import { gsap } from "gsap";

interface FireflyEffectAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const FireflyEffectAnimation: React.FC<FireflyEffectAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const circles = svgElement.querySelectorAll("circle");

    circles.forEach((circle) => {
      gsap.to(circle, {
        x: () => Math.random() * 20 - 10,
        y: () => Math.random() * 20 - 10,
        opacity: () => Math.random(),
        duration: animationSpeed,
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut",
        stagger: 0.1,
      });
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default FireflyEffectAnimation;
