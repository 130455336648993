import React, { useState, useEffect } from 'react';
import gifshot from 'gifshot';
import { Form, Button, ProgressBar, Row, Col, Accordion } from 'react-bootstrap';

let capturedFrames: HTMLCanvasElement[] = [];

const GifExporter = ({ svgContainerRef }: { svgContainerRef: React.RefObject<HTMLDivElement> }) => {
  const [currentWidth, setCurrentWidth] = useState<number>(500);
  const [currentHeight, setCurrentHeight] = useState<number>(500);
  const [newWidth, setNewWidth] = useState<number>(500);
  const [newHeight, setNewHeight] = useState<number>(500);
  const [exportProgress, setExportProgress] = useState<number>(0);

  // New configurable settings
  const [interval, setInterval] = useState<number>(0.2);
  const [numFrames, setNumFrames] = useState<number>(20);
  const [frameDuration, setFrameDuration] = useState<number>(1);
  const [sampleInterval, setSampleInterval] = useState<number>(40);
  const [numWorkers, setNumWorkers] = useState<number>(15);
  const [saveRenderingContexts, setSaveRenderingContexts] = useState<boolean>(false);
  const [crossOrigin, setCrossOrigin] = useState<string>('Anonymous');

  useEffect(() => {
    const svgElement = svgContainerRef.current?.querySelector('svg');

    if (svgElement) {
      const svgWidth = svgElement.viewBox.baseVal.width || svgElement.clientWidth || 500;
      const svgHeight = svgElement.viewBox.baseVal.height || svgElement.clientHeight || 500;

      setCurrentWidth(svgWidth);
      setCurrentHeight(svgHeight);
      setNewWidth(svgWidth);
      setNewHeight(svgHeight);
    }
  }, [svgContainerRef]);

  const captureAnimationFrame = async (svgContainerRef: React.RefObject<HTMLDivElement>) => {
    const svgElement = svgContainerRef.current?.querySelector('svg');

    if (svgElement) {
      const svgWidth = svgElement.viewBox.baseVal.width || svgElement.clientWidth || 500;
      const svgHeight = svgElement.viewBox.baseVal.height || svgElement.clientHeight || 500;

      const canvas = await rasterizeSvg(svgElement, svgWidth, svgHeight);
      capturedFrames.push(canvas);
    }
  };

  const rasterizeSvg = (svgElement: SVGElement, width: number, height: number): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas);
        }
        URL.revokeObjectURL(url);
      };
      img.src = url;
    });
  };

  const exportToGif = () => {
    const images = capturedFrames.map((canvas) => canvas.toDataURL());

    gifshot.createGIF(
      {
        images,
        gifWidth: newWidth,
        gifHeight: newHeight,
        interval,
        numFrames,
        frameDuration,
        sampleInterval,
        numWorkers,
        saveRenderingContexts,
        crossOrigin: 'Anonymous',
        progressCallback: (progress: number) => {
          setExportProgress(Math.round(progress * 100));
        },
      },
      (obj) => {
        if (!obj.error && obj.image) {
          const link = document.createElement('a');
          link.href = obj.image;
          link.download = 'animation.gif';
          link.click();
        }
      }
    );
  };

  const startRecording = () => {
    capturedFrames = [];
  
    // Wrap captureAnimationFrame in an anonymous function so it passes the necessary argument.
    const captureInterval = window.setInterval(() => {
      captureAnimationFrame(svgContainerRef);
    }, 200); // 5 frames per second
  
    setTimeout(() => {
      window.clearInterval(captureInterval); // Use window.clearInterval for browser environment
      exportToGif(); // Export the GIF after capturing frames
    }, 5000); // Record for 5 seconds
  };
  

  return (
    <Accordion className="mt-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>GIF Exporter Settings</Accordion.Header>
        <Accordion.Body>
          <Form>
            {/* Current Resolution */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Current Resolution:</Form.Label>
              <Col sm="8">
                <p className="mb-0">
                  {currentWidth} x {currentHeight}
                </p>
              </Col>
            </Form.Group>

            {/* New Resolution */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">New Width:</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={newWidth}
                  onChange={(e) => setNewWidth(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">New Height:</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={newHeight}
                  onChange={(e) => setNewHeight(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            {/* GIF Export Settings */}
            <h4 className="mb-3">GIF Export Settings</h4>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Interval (Seconds):</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={interval}
                  onChange={(e) => setInterval(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Number of Frames:</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={numFrames}
                  onChange={(e) => setNumFrames(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Frame Duration (Seconds):</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={frameDuration}
                  onChange={(e) => setFrameDuration(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Sample Interval:</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={sampleInterval}
                  onChange={(e) => setSampleInterval(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Number of Workers:</Form.Label>
              <Col sm="8">
                <Form.Control
                  type="number"
                  value={numWorkers}
                  onChange={(e) => setNumWorkers(Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">Save Rendering Contexts:</Form.Label>
              <Col sm="8">
                <Form.Check
                  type="checkbox"
                  checked={saveRenderingContexts}
                  onChange={(e) => setSaveRenderingContexts(e.target.checked)}
                />
              </Col>
            </Form.Group>

            {/* Start Recording Button */}
            <Button variant="primary" className="w-100 mb-4" onClick={startRecording}>
              Start Recording and Export GIF
            </Button>

            {/* Export Progress */}
            <div className="mb-3">
              <p>Export Progress:</p>
              <ProgressBar now={exportProgress} label={`${exportProgress}%`} />
            </div>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default GifExporter;
