import { useEffect } from "react";
import { gsap } from "gsap";

interface TranslateZAnimationProps {
    svgElement: SVGElement;
    translateAngle: number,
    animationSpeed: number;
  }

  const TranslateZAnimation: React.FC<TranslateZAnimationProps> = ({
    svgElement,
    translateAngle = 20,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        translateZ: translateAngle,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, translateAngle, animationSpeed]);
  
    return null;
  };
   
export default TranslateZAnimation;
