import { useEffect } from "react";
import { gsap } from "gsap";

interface WindmillRotationAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const WindmillRotationAnimation: React.FC<WindmillRotationAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("polygon, rect");

    gsap.to(svgElement, {
      rotation: 360,
      duration: animationSpeed,
      repeat: -1,
      ease: "linear",
      transformOrigin: "50% 50%",
    });
  }, [svgElement, animationSpeed]);

  return null;
};

export default WindmillRotationAnimation;
