import { useEffect } from "react";
import { gsap } from "gsap";

interface TranslateXAnimationProps {
    svgElement: SVGElement;
    translateAngle: number,
    animationSpeed: number;
  }

  const TranslateXAnimation: React.FC<TranslateXAnimationProps> = ({
    svgElement,
    translateAngle = 20,
    animationSpeed = 1,
  }) => {
    useEffect(() => {
      gsap.to(svgElement, {
        translateX: translateAngle,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      });
    }, [svgElement, translateAngle, animationSpeed]);
  
    return null;
  };
   
export default TranslateXAnimation;
