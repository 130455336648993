import { FaCircle, FaSquare, FaPlay, FaStar, FaEllipsisH, FaDiceSix } from "react-icons/fa";

interface ShapeSelectorProps {
  shape: "circle" | "square" | "triangle" | "star"| "oval" | "hexagon";
  setShape: (shape: "circle" | "square" | "triangle" | "star" | "oval" | "hexagon") => void;
}

const ShapeSelector: React.FC<ShapeSelectorProps> = ({ shape, setShape }) => {
  return (
    <div className="shape-selector">
      <h4>Shape</h4>
      <div className="btn-group shape-btn-group" role="group">
  {/* Circle Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "circle" ? "active" : ""}`}
        onClick={() => setShape("circle")}
      >
        <FaCircle size={14} /> {/* Smaller icon size */}
      </button>

      {/* Square Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "square" ? "active" : ""}`}
        onClick={() => setShape("square")}
      >
        <FaSquare size={14} /> {/* Smaller icon size */}
      </button>

      {/* Triangle Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "triangle" ? "active" : ""}`}
        onClick={() => setShape("triangle")}
      >
        <FaPlay style={{ transform: "rotate(90deg)" }} size={14} />
      </button>

      {/* Star Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "star" ? "active" : ""}`}
        onClick={() => setShape("star")}
      >
        <FaStar size={14} />
      </button>

      {/* Oval Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "oval" ? "active" : ""}`}
        onClick={() => setShape("oval")}
      >
        <FaEllipsisH size={14} />
      </button>

      {/* Hexagon Shape Button */}
      <button
        type="button"
        className={`btn shape-btn ${shape === "hexagon" ? "active" : ""}`}
        onClick={() => setShape("hexagon")}
      >
        <FaDiceSix size={14} />
      </button>
    </div>

    </div>
  );
};

export default ShapeSelector;
