import { useEffect } from "react";
import { gsap } from "gsap";

interface ExplosionEffectAnimationProps {
    svgElement: SVGElement;
    animationSpeed: number;
  }
const ExplosionEffectAnimation: React.FC<ExplosionEffectAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    const elements = svgElement.querySelectorAll("circle, rect");

    gsap.fromTo(
        svgElement,
      {
        scale: 0,
        x: 0,
        y: 0,
      },
      {
        scale: 1,
        x: () => Math.random() * 200 - 100,
        y: () => Math.random() * 200 - 100,
        duration: animationSpeed,
        ease: "power4.out",
        repeat: -1,
        yoyo: true,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default ExplosionEffectAnimation;
