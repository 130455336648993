import React, { useEffect } from "react";
import { gsap } from "gsap";

interface MindmapAnimationProps {
  nodes: NodeListOf<SVGElement>; // Nodes (mindmap items) to animate
  edges: NodeListOf<SVGElement>; // Edges (lines connecting nodes)
  duration: number; // Duration of the animation
}

const MindmapAnimation: React.FC<MindmapAnimationProps> = ({ nodes, edges, duration }) => {
  useEffect(() => {
    if (nodes.length === 0 || edges.length === 0) return;

    // Apply animation to nodes
    gsap.from(nodes, {
      opacity: 0,
      duration: duration,
      stagger: 0.2, // Stagger the animation for each node
      ease: "power2.out",
    });

    // Apply animation to edges
    gsap.from(edges, {
      strokeDasharray: 1000, // Make the line appear to be drawn
      strokeDashoffset: 1000,
      duration: duration,
      stagger: 0.2, // Stagger the drawing of each edge
      ease: "power2.out",
    });
  }, [nodes, edges, duration]);

  return null; // This component handles animation, no UI to render
};

export default MindmapAnimation;
