import { useEffect } from "react";
import { gsap } from "gsap";

interface PoppingAnimationProps {
  svgElement: SVGElement;
  animationSpeed: number;
}

const PoppingAnimation: React.FC<PoppingAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    if (!svgElement) return;

    // Apply GSAP popping animation to the selected element
    gsap.to(svgElement, {
      scale: 1.5, // Popping effect
      duration: animationSpeed * 0.5,
      repeat: -1, // Infinite loop
      yoyo: true, // Reverses back to original size
      ease: "back.inOut(1.7)", // Smooth easing
    });

    // Cleanup: reset the element back to its original size when animation is removed
    return () => {
      gsap.killTweensOf(svgElement);
      gsap.to(svgElement, { scale: 1 }); // Reset the scale when animation stops
    };
  }, [svgElement, animationSpeed]);

  return null;
};

export default PoppingAnimation;
