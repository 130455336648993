import { useEffect } from "react";
import { gsap } from "gsap";

interface FloatAnimationProps {
  svgElement: SVGElement;
  floatDistance?: number;  // Optional distance for the floating effect
  animationSpeed?: number; // Optional speed for the floating effect
}

const FloatAnimation: React.FC<FloatAnimationProps> = ({
  svgElement,
  floatDistance = 20,  // Default floating distance of 20px
  animationSpeed = 2,  // Default animation duration of 2 seconds
}) => {
  useEffect(() => {
    gsap.to(svgElement, {
      y: `-=${floatDistance}`,
      duration: animationSpeed,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,  // Make the floating go back and forth
    });
  }, [svgElement, floatDistance, animationSpeed]);

  return null;
};

export default FloatAnimation;
