import { useEffect } from "react";
import { gsap } from "gsap";

interface WobbleAnimationProps {
  svgElement: SVGElement;
  animationSpeed: number;
}

const WobbleAnimation: React.FC<WobbleAnimationProps> = ({ svgElement, animationSpeed }) => {
  useEffect(() => {
    gsap.fromTo(
      svgElement,
      { rotation: -10 },
      {
        rotation: 10,
        duration: animationSpeed,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      }
    );
  }, [svgElement, animationSpeed]);

  return null;
};

export default WobbleAnimation;
